import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Trainings from './components/trainings/Trainings'
import Gallery from './components/gallery/Gallery'
import PrivateClass from './components/privateClass/PrivateClass'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import { Helmet } from "react-helmet";


const App = () => {
  return (
    <>
        <Header />
        <Helmet>
          <title>Antony Grappling Division</title>
          <meta 
            name="description"
            content="Tréningy brazílskeho jiu jitsu, grapplingu, mma. Súkromné tréningy Brazilian jiu jitsu, Nogi, Grappling" />
          <meta name="keywords" content="Grappling, Brazilian jiu jitsu, Nogi, MMA, Wrestling, Zápasenie." />
        </Helmet>
        <Nav />
        <About />
        <Experience />
        <Gallery />
        <Trainings />
        <PrivateClass /> 
        <Contact />
        <Footer />
    </>
  )
}

export default App