import React from 'react'
import {TiMessages} from 'react-icons/ti'

const CTA = () => {
  return (
    <div className='cta'>
      <a href="#contact" className='btn'>Registrujte sa</a>
      <a href="#trainings" className='btn btn-primary'>Rozvrh tréningov</a>
    


    </div>
  )
}

export default CTA